var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"home",class:{ home: true, 'home-with-bg': _vm.bgPrepared, 'fix-scrollbar': true }},[_c('WallpaperShow'),_c('div',{class:{ blocks: true }},[_vm._l((_vm.fixedCards),function(item,index){return _c('div',{key:'s' + index,class:{
        card: true,
        'fix-scrollbar': item.ifFixScrollbar,
        'card-blurred': _vm.bgPrepared && _vm.settings.useBlur,
        'card-no-bg': item.nobg == true,
        'card-has-float': item.hasFloat == true,
        'card-w-1-h-1': item.card == 'w-1-h-1',
        'card-w-1-h-2': item.card == 'w-1-h-2',
        'card-w-2-h-1': item.card == 'w-2-h-1',
        'card-w-2-h-2': item.card == 'w-2-h-2',
        'card-w-1-h-auto': item.card == 'w-1-h-auto',
        'card-w-2-h-auto': item.card == 'w-2-h-auto',
        'card-w-4-h-auto': item.card == 'w-4-h-auto',
      }},[_c(item.component,{tag:"component"})],1)}),_vm._l((_vm.cards),function(item,index){return (!item.hide)?_c('div',{key:index,ref:item.component,refInFor:true,class:{
        card: true,
        'fix-scrollbar': item.ifFixScrollbar,
        'card-blurred': _vm.bgPrepared && _vm.settings.useBlur,
        'card-no-bg': item.nobg == true,
        'card-has-float': item.hasFloat == true,
        'card-w-1-h-1': item.card == 'w-1-h-1',
        'card-w-1-h-2': item.card == 'w-1-h-2',
        'card-w-2-h-1': item.card == 'w-2-h-1',
        'card-w-2-h-2': item.card == 'w-2-h-2',
        'card-w-1-h-auto': item.card == 'w-1-h-auto',
        'card-w-2-h-auto': item.card == 'w-2-h-auto',
        'card-w-4-h-auto': item.card == 'w-4-h-auto',
      }},[_c(item.component,{tag:"component"})],1):_vm._e()})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }