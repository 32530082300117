var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    'wallpaper-trans': true,
    'wallpaper-show': _vm.bgPrepared,
    'wallpaper-hided': _vm.wallpaperDescHided,
    'wallpaper-closed': !_vm.bgPrepared,
  }},[(_vm.bgPrepared)?_c('div',{staticClass:"desc"},[_c('transition',{attrs:{"name":"fade"}},[(!_vm.wallpaperDescHided)?_c('div',{staticClass:"wallpaper-info"},[_c('div',{staticClass:"left"},[(_vm.showDesc)?_c('a',{staticClass:"author-info ef-fadein text-shadow",attrs:{"href":_vm.wallpaperData.links.html,"target":"_blank"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.wallpaperData.user.profile_image.small),expression:"wallpaperData.user.profile_image.small"}],staticClass:"avatar",attrs:{"alt":"","srcset":""}}),_c('div',{staticClass:"info"},[_c('span',{staticClass:"name"},[_vm._v(_vm._s(_vm.wallpaperData.user.name))]),_c('span',{staticClass:"desc"},[_vm._v("来自 Unsplash，点击查看详情")])])]):_c('a',{staticClass:"author-info ef-fadein text-shadow"},[_c('div',{staticClass:"info"},[_c('span',{staticClass:"name"},[_vm._v("壁纸加载失败，要不点击右边刷新试试？")])])])]),_c('div',{staticClass:"right"},[(_vm.showDesc)?_c('a',{staticClass:"download",attrs:{"href":_vm.wallpaperData.links.download,"target":"_blank"}},[_c('button',{class:{ 'icon-button': true, 'wallpaper-button': true }},[_c('span',{class:{
                  iconfont: true,
                  'icon-download': true,
                }})])]):_vm._e(),_c('button',{class:{
              'icon-button': true,
              'wallpaper-button': true,
            },on:{"click":function($event){return _vm.changeWallpaper()}}},[_c('span',{class:{
                iconfont: true,
                'icon-refresh': true,
                spinning: _vm.spinning,
              }})])])]):_vm._e()]),_c('div',{staticClass:"hide",on:{"click":function($event){return _vm.hideDesc()},"mouseover":function($event){_vm.enterdHide = true},"mouseout":function($event){_vm.enterdHide = false}}},[_c('span',{class:{
          iconfont: true,
          'icon-chevron-down': _vm.wallpaperDescHided,
          'icon-chevron-up': !_vm.wallpaperDescHided,
          'enterd-hide': _vm.enterdHide,
          'btn-no-blur': !_vm.settings.useBlur,
        }})])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }