var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    header: true,
    'header-pwa-colored': _vm.pwaHeaderBarColored,
    //'header-with-bg': ifScrolled,
    //'header-with-bg-blurred': ifScrolled && bgPrepared,
  }},[_c('div',{staticClass:"rounded-pwa-corner"}),_c('div',{staticClass:"header-content"},[_c('div',{staticClass:"logo-box"},[_c('span',{class:{ logo: true, 'logo-blurred': _vm.bgPrepared && !_vm.pwaHeaderBarColored, 'logo-pwa-colored':_vm.pwaHeaderBarColored},domProps:{"textContent":_vm._s(_vm.logoText)}})]),_c('div',{class:{
      center: true,
      'big-clock': !_vm.ifScrolled && !_vm.title && _vm.bgPrepared && !_vm.wallpaperDescHided,
      'big-clock-smaller': !_vm.ifScrolled && !_vm.title && !_vm.bgPrepared,
    }},[_c('transition',{attrs:{"name":"fade"}},[(_vm.title)?_c('div',{staticClass:"title-box"},[_c('span',{class:{ title: true, 'title-blurred': _vm.bgPrepared && !_vm.pwaHeaderBarColored, 'title-pwa-colored':_vm.pwaHeaderBarColored },domProps:{"textContent":_vm._s(_vm.title)}})]):_c('div',{staticClass:"profile-box"},[_c('TinyClock',{class:{ clock: true }})],1)])],1),_c('div',{staticClass:"right"},[_c('OneSentence')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }