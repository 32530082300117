var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tiny-nav"},[_c('div',{class:{
    'header':true,
    //'header-blurred':bgPrepared,
    //'text-shadow':bgPrepared
    }},[_c('span',{staticClass:"title"},[_vm._v("我的导航")]),_c('router-link',{attrs:{"to":"/navigation"}},[_c('button',{staticClass:"icon-button"},[_c('span',{staticClass:"label"},[_vm._v("更多 / 自定义")]),_c('span',{staticClass:"iconfont icon-chevron-right"})])])],1),_c('div',{staticClass:"nav-list"},_vm._l((_vm.navs.favorites),function(item,index){return _c('a',{key:index,class:{
        'nav-item': true,
        'ef-float': true,
        'nav-item-blurred': _vm.bgPrepared && _vm.settings.useBlur,
        'nav-item-wide': _vm.checkSubsites(item.attributes),
      },attrs:{"href":item.attributes.url,"target":"_blank"}},[_c('div',{class:{
          left: true,
          'left-wide': _vm.checkSubsites(item.attributes),
        }},[_c('div',{staticClass:"top"},[_c('div',{staticClass:"icon-area",style:('background-color: ' + item.attributes.color)},[_c('img',{class:{
                icon: true,
                'icon-no-padding': item.attributes.no_padding,
              },attrs:{"src":_vm.getIcon(item),"alt":"","srcset":""}})])]),_c('span',{staticClass:"title fix-text-overflow",domProps:{"textContent":_vm._s(item.attributes.title)}})]),(_vm.checkSubsites(item.attributes))?_c('div',{staticClass:"subsites-list"},_vm._l((item.attributes.subsites.data),function(item_2,index_2){return _c('a',{key:index_2,class:{
            'subsite-item': true,
            'subsite-item-blurred': _vm.bgPrepared && _vm.settings.useBlur,
          },attrs:{"href":item_2.attributes.url,"target":"_blank"}},[_c('span',{staticClass:"title fix-text-overflow",domProps:{"textContent":_vm._s(item_2.attributes.title)}})])}),0):_vm._e()])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }